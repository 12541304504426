<template>
	<v-container
		class="bg-color"
		fill-height
	>
		<v-row
			align="center"
		>
			<v-col>
				<v-card
					class="pa-2 pt-10 pb-10 text-center"
				>
					<span
						style="position: relative;"
					>
						<v-icon 
							x-large
							color="success2"
						>mdi-cellphone-android</v-icon>
						
						<v-icon 
							small
							color="success2"
							style="position: absolute; right: 2px; top :2px; z-index: 99; background: white;">mdi-shield-check-outline</v-icon>
					</span>
					
					<v-card-title
						class="text-subtitle-1 font-weight-bold"
					>
						PayLink 서비스 신청 시 <br/>본인확인(최초1회)이 필요합니다
					</v-card-title>
					
					<v-card-text
						class="text-xs"
					>
						회원정보는 본인의 동의없이 공개되지 않으며 PayLink 개인정보 보호정책에 의해 보호받고 있습니다.
					</v-card-text>
					
					<v-card-actions>
						<v-btn
							block
							color="success2"
							@click="onRequest"
							:disabled="disabled"
						>
							휴대폰 인증
						</v-btn>
					</v-card-actions>
					
				</v-card>
			</v-col>
		</v-row>

		<form name="form_chk" method="get">
			<input type="hidden" name="m" value="checkplusService">     <!-- 필수 데이타로, 누락하시면 안됩니다. -->
			<input type="hidden" name="EncodeData" value="">  <!-- 위에서 업체정보를 암호화 한 데이타입니다. -->
			<input type ="hidden" name="recvMethodType" value="get">
			<!--        
			<a href="javascript:fnPopup();"> CheckPlus 안심본인인증 Click</a>
			-->
		</form>
	</v-container>
</template>

<script>
	export default{
		name: 'SignService'
		,props: ['program', 'item', 'callBack']
		,data: function(){
			return {
				disabled: false
			}
		}
		,methods: {
			onRequest: function(){
				this.$emit('axios', {
					request_type: 'post'
					,origin_url: 'https://nice.payhub.co.kr/check-pay-plus/request'
					,callBack: 'niceResult'
					,request_header: {
						'Access-Control-Allow-Origin': '*'
						,'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
						,'Access-Control-Allow-Headers': 'Content-type, Authoriztion, Content-Length, X-Requested-With'
					}
				})
			}
			,niceResult: function(call){
				this.fnPopup(call.items.enc_data)
			}
			,fnPopup: function(encode) {
				window.name ="Parent_window";
				window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
				document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
				document.form_chk.target = "popupChk";
				document.form_chk.EncodeData.value = encode

				document.form_chk.submit();
			}
			, messageResult: function(e){
				const call = e.data
console.log(call)
				if(call.success){
					this.$set(this.item, 'payload', call.payload)
					this.$set(this.item, 'certificationInformation', call.payload)
					this.$set(this.item, 'mobilPhoneNumber', call.phone_no)
					this.disabled = true
				}else{
					this.disabled = false
					this.$emit('alertMsg', 'error', call.resp_message)
				}
			}
		}
		,mounted: function(){

			window.removeEventListener('message', this.messageResult)
			window.addEventListener('message', this.messageResult);
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'niceResult'){
						this.niceResult(call)
					}
				}
			}
		}
	}
</script>
